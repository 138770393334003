import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

// Connects to data-controller="chart"
export default class extends Controller {
  connect() {
    console.log("Charts connected")
  }

  initialize()
  {
    this.element.setAttribute("data-action", "click->chart#draw_chart");
  }

  draw_chart()
  {
    const chart = document.getElementById("charts")
    var old_svg = chart.querySelector("svg")

    if (old_svg)
    {
      old_svg.remove()
    }
    else
    {

      // get the data type requested

      var chart_type = this.element.getAttribute("data-chart-type") 
      var chart_data = this.element.getAttribute("data-chart-data") 
      var chart_padding_str = this.element.getAttribute("data-chart-padding")
      var chart_period = this.element.getAttribute("data-chart-period")

      if (!chart_padding_str) 
      {
        chart_padding_str = "0"
      }

      if (!chart_period) 
        {
          chart_period = "1.month"
        }
  
      const chart_padding = parseFloat(chart_padding_str)

      // remove the previous chart
      d3.select("#charts").selectAll("svg").remove()

      // get width of current content

      const card = document.getElementById("asset-card")

      let card_width = 600
      if (card != null)
      {
        card_width = card.offsetWidth
      }

      // set the dimensions and margins of the graph
      const margin = {top: 10, right: 30, bottom: 30, left: 60},
        width = card_width - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      const svg = d3.select("#charts")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

      
      // gridlines in x axis function
      function make_x_gridlines(x) {		
        return d3.axisBottom(x)
            .ticks(8)
      }

      // gridlines in y axis function
      function make_y_gridlines(y) {		
        return d3.axisLeft(y)
            .ticks(5)
      }

      var url = this.element.getAttribute("data-chart-url")
               + "?type=" + chart_type + "&data_type=" + chart_data + "&period=" + chart_period

      d3.json(url).then( 
        // When reading the csv, I must format variables:
        function(d)
        {
          return d.map(obj => ({ date : d3.timeParse("%Y-%m-%d %H:%M:%S")(obj.date), price : obj.price }))
        })
        .then(

          // Now I can use this dataset:
          function(data) 
          {
            // Add X axis --> it is a date format
            const x = d3.scaleTime()
              .domain(d3.extent(data, function(d) { return d.date; }))
              .range([ 0, width ]);
            var xAxis = svg.append("g")
              .attr("transform", "translate(0," + height + ")")
              .call(d3.axisBottom(x));

            // Add Y axis
            const y = d3.scaleLinear()
              .domain([d3.min(data, function(d) { return d.price - chart_padding; }), 
                        d3.max(data, function(d) { return d.price + chart_padding; })])
              .range([ height, 0 ]);
            var yAxis = svg.append("g")
              .call(d3.axisLeft(y));

            // Add a clipPath: everything out of this area won't be drawn.
            const clip = svg.append("defs").append("svg:clipPath")
                .attr("id", "clip")
                .append("svg:rect")
                .attr("width", width )
                .attr("height", height )
                .attr("x", 0)
                .attr("y", 0);

            // Add brushing
            const brush = d3.brushX()                   // Add the brush feature using the d3.brush function
                .extent( [ [0,0], [width,height] ] )  // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
                .on("end", updateChart)               // Each time the brush selection changes, trigger the 'updateChart' function

            // Create the line variable: where both the line and the brush take place
            const line = svg.append('g')
              .attr("clip-path", "url(#clip)")

            // Add the line
            line.append("path")
              .datum(data)
              .attr("class", "line")  // I add the class line to be able to modify this line later on.
              .attr("fill", "none")
              .attr("stroke", "steelblue")
              .attr("stroke-width", 1.5)
              .attr("d", d3.line()
                .x(function(d) { return x(d.date) })
                .y(function(d) { return y(d.price) })
                )

            // Add the brushing
            line
              .append("g")
                .attr("class", "brush")
                .call(brush);

            // add the X gridlines
            svg.append("g")			
                .attr("class", "grid")
                .attr("transform", "translate(0," + height + ")")
                .call(make_x_gridlines(x)
                    .tickSize(-height)
                    .tickFormat("")
                )

            // add the Y gridlines
            svg.append("g")			
                .attr("class", "grid")
                .call(make_y_gridlines(y)
                    .tickSize(-width)
                    .tickFormat("")
                )

            // A function that set idleTimeOut to null
            let idleTimeout
            function idled() { idleTimeout = null; }

            // A function that update the chart for given boundaries
            function updateChart(event, d) 
            {

              // What are the selected boundaries?
              var extent = event.selection

              // If no selection, back to initial coordinate. Otherwise, update X axis domain
              if(!extent)
              {
                if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // This allows to wait a little bit
                x.domain([ 4,8])
              }
              else
              {
                x.domain([ x.invert(extent[0]), x.invert(extent[1]) ])
                line.select(".brush").call(brush.move, null) // This remove the grey brush area as soon as the selection has been done
              }

              // Update axis and line position
              xAxis.transition().duration(1000).call(d3.axisBottom(x))
              line
                  .select('.line')
                  .transition()
                  .duration(1000)
                  .attr("d", d3.line()
                    .x(function(d) { return x(d.date) })
                    .y(function(d) { return y(d.price) })
                  )
            }

            // If user double click, reinitialize the chart
            svg.on("dblclick",function()
            {
              x.domain(d3.extent(data, function(d) { return d.date; }))
              xAxis.transition().call(d3.axisBottom(x))
              line
                .select('.line')
                .transition()
                .attr("d", d3.line()
                  .x(function(d) { return x(d.date) })
                  .y(function(d) { return y(d.price) })
              )
            });
          })
    }
  }

}
